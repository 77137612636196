<template>
  <div class="articles-page">
    <div class="title-part">
      <div class="container">
        <h1 v-scroll-animation="'animate__slideInUp'">مقالات تنوين</h1>
        <hr v-scroll-animation="'animate__fadeInDown'"/>
        <p v-scroll-animation="'animate__slideInDown'">
          هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن
          تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.
        </p>
      </div>
      <div class="articles-container " v-if="this.$store.state.articles.articles.length>0">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-lg-6" v-scroll-animation="'animate__zoomIn'" v-bind:key="item.id"
                 v-for="item in this.$store.state.articles.articles.slice(0,2)"
            >
              <router-link class="article-item" :to="{name:'singleArticle',params:{id:item.id}}">
                <img
                    :src="item.image_full_path"
                    alt="Article Image"
                />
                <div class="date">
                  <p>{{ item.created_at }}</p>
                </div>
                <div class="title">
                  <p>
                    {{ item.title }}
                  </p>
                </div>
                <div class="description">
                  <p>
                    {{ item.short_description }}
                  </p>
                </div>
                <div class="author">
                  <p>{{item.blog_author.name}}</p>
                </div>
              </router-link>
            </div>
          </div>
          <hr/>
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 mb-4" v-bind:key="item.id"
                 v-for="item in this.$store.state.articles.articles.slice(2)"
            >
              <router-link class="article-item" v-scroll-animation="'animate__zoomIn'"
                           :to="{name:'singleArticle',params:{id:item.id}}">
                <img
                    :src="item.image_full_path"
                    alt="Article Image"
                />
                <div class="date">
                  <p>{{ item.created_at }}</p>
                </div>
                <div class="title">
                  <p>
                    {{ item.title }}
                  </p>
                </div>
                <div class="description">
                  <p>
                    {{ item.short_description }}
                  </p>
                </div>
                <div class="author">
                  <p>{{item.blog_author.name}}</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "articles",
  created() {
    this.$store.dispatch("loading/show");
    this.$store.dispatch("articles/getArticles")
        .then(() => {
          this.$store.dispatch("loading/hide");
        })
        .catch(err => {
          console.log(err);
        });
  },
}
</script>

<style lang="scss" scoped>
.articles-page {
  padding: 3.5em 0;
  background-color: #fbfcfc;

  .title-part {
    h1 {
      font-weight: 700;
      font-size: 2.2rem;
    }

    p {
      text-align: start;
      font-size: 1.15rem;
    }
  }

  .articles-container {
    margin-top: 3em;
    hr{
      margin-bottom: 2.5em;
    }

    .article-item {
      display: block;
      margin-bottom: 3em;

      img {
        border-radius: 8px;
        width: 100%;
        height: 19em;
        object-fit: cover;
        margin-bottom: 0.35em;
      }

      .date {
        p {
          font-size: 0.85rem;
          text-align: end;
          font-weight: 700;
          color: $myBlue;
          margin-bottom: 0;
        }
      }

      .title {
        p {
          text-align: start;
          font-weight: 700;
          color: $myViolet;
          font-size: 1.4rem;
        }
      }

      .description {
        p {
          text-align: start;
          color: $myBlue;
          font-size: 1.05rem;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .author {
        p {
          text-align: start;
          color: $myBlue;
          font-weight: 700;
          font-size: 1.1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .articles-page {
    .articles-container {
      hr {
        display: none;
      }
    }
  }
}
</style>