<template>
  <div class="singleArticle-page" v-if="postData">
    <section class="mainPost">
      <div class="container">
        <div class="post-image">
          <img :src="postData.image_full_path" class="img-fluid" alt="Post Image"
               v-scroll-animation="'animate__zoomIn'">
          <h1 v-scroll-animation="'animate__slideInDown'">{{ postData.title }}</h1>
        </div>
        <div class="post-content">
          <div class="html-content" v-html="postData.content" v-scroll-animation="'animate__slideInDown'"></div>
          <div class="post-metadata" v-if="postData.tags_list.length>0">
            <h4 v-scroll-animation="'animate__slideInDown'">كلمات مفتاحية</h4>
            <div class="post-metadata-items-container tags" v-scroll-animation="'animate__slideInRight'">
              <router-link :to="{name:'Search',params:{keyword:item.name,isPodcast:false}}" :key="item.name"

                           v-for="item in postData.tags_list">
                <span>{{ item.name }}</span>
              </router-link>
            </div>
          </div>
          <div class="post-metadata" v-if="postData.sources_list.length>0">
            <h4 v-scroll-animation="'animate__slideInDown'">المصـــــــــــــــــــــــــــــــادر</h4>
            <div class="post-metadata-items-container">
              <a v-scroll-animation="'animate__slideInRight'" :href="item.url" target="_blank" :key="item.name"
                 v-for="item in postData.sources_list">
                <span>{{ item.title }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="newsletter-section">
      <div class="container">
        <div class="newsletter-container">
          <div class="text-part">
            <font-awesome-icon icon="envelope-open-text" v-scroll-animation="'animate__fadeInDown'"/>
            <p v-scroll-animation="'animate__fadeInDown'">ما رأيك في الانضمام إلى القائمة البريدية</p>
          </div>
          <div class="form-part" v-scroll-animation="'animate__slideInDown'">
            <form id="newsletterForm" method="post" :action="this.$store.state.generalStore.mailchimpActionLink"
                  target="_blank">
              <input
                  type="email"
                  placeholder="أدخل بريدك الإلكتروني"
                  required
              />
              <div class="submitNewsletterForm-button" @click="submitNewsletterForm">
                <p>أرسل</p>
                <font-awesome-icon icon="paper-plane"/>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <section class="other-episodes-section" v-if="this.$store.state.articles.authorPosts.length>0">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 mb-4" v-scroll-animation="'animate__zoomIn'"
               v-for="item in this.$store.state.articles.authorPosts">
            <router-link class="article-item" :to="{name:'singleArticle',params:{id:item.id}}">
              <img
                  class="img-fluid"
                  :src="item.image_full_path"
                  alt="Article Image"
              />
              <div class="date">
                <p>{{ item.created_at }}</p>
              </div>
              <div class="title">
                <p>
                  {{ item.title }}
                </p>
              </div>
              <div class="description">
                <p>
                  {{ item.short_description }}
                </p>
              </div>
              <div class="author">
                <p>{{ item.blog_author.name }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: "singleArticle",
  data() {
    return {
      postData: null,
    };
  },
  watch: {
    '$route.params.id': {
      handler: function (search) {
        this.getSingleArticle();
      },
      deep: true,
      immediate: true
    }
  },
  methods:{
    submitNewsletterForm(){
      let myForm = document.getElementById("newsletterForm");
      myForm.submit();
    },
    getSingleArticle(){
      this.$store.dispatch("loading/show");
      this.$store.dispatch("articles/getSingleArticle", {post_id: this.$route.params.id})
          .then(() => {
            this.postData = this.$store.state.articles.singleArticle;
            setTimeout(()=>{
              this.$store.dispatch("loading/hide");
            },500);

          })
          .catch(err => {
            console.log(err);
          });
    },
  },
  created() {
    // this.getSingleArticle();
  },
}
</script>

<style lang="scss" >
.singleArticle-page {
  padding: 3.5em 0 0 0;
  background-color: #fbfcfc;

  .post-image {
    margin-bottom: 1.5em;

    img {
      width: 100%;
      height: 24em;
      object-fit: cover;
      object-position: center;
      border-radius: 8px;
      margin-bottom: 1em;
    }

    h1 {
      font-weight: 700;
    }
  }

  .post-content {
    overflow-x: hidden;

    .html-content {
      margin-bottom: 4em;
      p{
        font-size: 1.3rem;
      }
    }

    .post-metadata {
      margin-bottom: 3em;

      h4 {
        color: $myViolet;
        font-weight: 700;
        font-size: 1.8rem;
        margin-bottom: 0.75em;
      }

      .post-metadata-items-container {
        a {
          color: $myBlue;
          display: block;
          font-weight: 700;
        }

        &.tags {
          a {
            font-weight: normal;
            display: inline;
            color: $myBlue;
            border-radius: 1em;
            border: 1px solid $myBlue;
            padding: 0.15em 1.2em;
            margin-inline-end: 0.5em;
            transition: 0.3s ease-in-out;

            &:hover {
              background-color: $myBlue;
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .newsletter-section {
    margin-top: 5em;
    margin-bottom: -4.5em;
    position: relative;
    z-index: 2;

    .newsletter-container {
      padding: 1.4em 1.5em;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 0 12px rgba(#000000, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .text-part {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 30em;
        max-width: 90vw;

        svg {
          font-size: 3rem;
          margin-inline-end: 0.35em;
          color: $myBlue;
        }

        p {
          font-weight: 700;
          margin-bottom: 0;
          font-size: 1.6rem;
        }
      }

      .form-part {
        margin-top: 1.5em;
        width: 30em;
        max-width: 90vw;

        form {
          width: 100%;
          position: relative;

          input {
            border-radius: 1em;
            padding: 0.2em 1em;
            width: 100%;
            border: 2px solid rgba(#000, 0.2);
          }

          .submitNewsletterForm-button {
            position: absolute;
            top: 0;
            left: 0;
            background-color: $myBlue;
            width: 6em;
            border-radius: 6em;
            height: 2.2em;
            overflow: hidden;
            cursor: pointer;

            p,
            svg {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              margin-bottom: 0;
              color: white;
            }

            svg {
              left: 150%;
              transition: 0.3s cubic-bezier(0.47, 1.64, 0.41, 0.8);
              transition-delay: 0s;
              transform: translateY(-50%) scaleX(-0.8);
            }

            p {
              left: 50%;
              transform: translate(-50%, -50%);
              font-weight: 700;
              transition: 0.3s ease-in-out;
            }

            &:hover {
              svg {
                left: 50%;
                transform: translate(-50%, -50%) scaleX(-1);
                transition-delay: 0.3s;
              }

              p {
                left: -50%;
              }
            }
          }
        }
      }
    }
  }

  .other-episodes-section {
    background-color: #f5f6f7;
    padding-top: 9.5em;
    padding-bottom: 2em;

    .article-item {
      display: block;
      margin-bottom: 3em;

      img {
        border-radius: 8px;
        width: 100%;
        height: 18em;
        object-fit: cover;
        margin-bottom: 0.35em;
      }

      .date {
        p {
          font-size: 0.85rem;
          text-align: end;
          font-weight: 700;
          color: $myBlue;
          margin-bottom: 0;
        }
      }

      .title {
        p {
          text-align: start;
          font-weight: 700;
          color: $myViolet;
          font-size: 1.4rem;
        }
      }

      .description {
        p {
          text-align: start;
          color: $myBlue;
          font-size: 1.05rem;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .author {
        p {
          text-align: start;
          color: $myBlue;
          font-weight: 700;
          font-size: 1.1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .singleArticle-page {
    .newsletter-section {
      .newsletter-container {
        .form-part{
          display: flex;
          justify-content: center;
          form{
            width: 90%;
          }
        }
        .text-part {
          svg {
            font-size: 2rem;
          }

          p {
            font-size: 1.15rem;
          }
        }
      }
    }
  }
}
</style>